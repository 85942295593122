 
  .modal-content {
    padding:30px;
  }
  .modal:before { 
            content: ''; 
            display: inline-block; 
            height: 100%; 
            vertical-align: middle; 
        } 
          
        .modal-dialog { 
            display: inline-block; 
            vertical-align: middle; 
        } 

        .buttonInside{
            position:relative;
            margin-bottom:10px;
          }
          /* #fname{
            height:25px;
            width:100%;
            padding-left:10px;
            border-radius: 4px;
            border:none;outline:none;
          } */
          #showHelp{
            position:absolute;
            right: 0px;
            top: 4px;
            border:none;
            height:20px;
            width:20px;
            border-radius:100%;
            outline:none;
            text-align:center;
            font-weight:bold;
            padding:2px;
          }
          span:hover{
            cursor:pointer;
          }
          #help{
            display:none;
            font-style:italic;
            font-size:0.8em;
          }
          .bg{
            background-color: #47974B;
            height: 710px;
            position: relative;
        }
        #signup{
            margin-top: 12%;
            background-color: #ffffff;
            padding-bottom: 70px;
        }
        #form{
            margin-top: 12%;
            background-color: #ffffff;
        }
        #logo{
            width: 50%;
            padding-top: 30px;
            
        }
        #head2{
            padding-top: 5%;
        }
        .email{
            width: 60%;
        }
        #continuebtn{
            background-color: #47974B;
            color: white;
            width: 60%;
            
        }
        
        