

.input_group_addon {
  // padding: 6px 12px;
  display: table-cell;
  border-right: 0;
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 1;
  color: #555;
  text-align: center;
  background-color: #eee;
  border: 1px solid #ccc;
  border-radius: 4px;
}
.apply-button{
	background-color:#47974B;
	border:2px solid #ddd;
	border-radius:5px;
	color:#fff;
}
 .apply-button:hover{
	background-color:#2badb3;
	color:#fff;
}
 .apply a:hover{
	background-color:#2badb3;
	color:#fff;
}
/* Contact Section
--------------------------------*/
#contact {
  
  padding: 45px 0;
}

#contact .info {
  color: #333333;
}

#contact .info i {
  font-size: 32px;
  color: #47974B ;
  float: left;
}

#contact .info .address-info {
  padding: 0 0 10px 22px;
  line-height: 30px;
  font-size:16px;

}

#contact .form #sendmessage {
  color: #47974B ;
  border: 1px solid #47974B ;
  display: none;
  text-align: center;
  padding: 15px;
  font-weight: 600;
  margin-bottom: 15px;
}

#contact .form #errormessage {
  color: red;
  display: none;
  border: 1px solid red;
  text-align: center;
  padding: 15px;
  font-weight: 600;
  margin-bottom: 15px;
}

#contact .form #sendmessage.show, #contact .form #errormessage.show, #contact .form .show {
  display: block;
}

#contact .form .validation {
  color: red;
  display: none;
  margin: 0 0 20px;
  font-weight: 400;
  font-size: 13px;
}

#contact .form input, #contact .form textarea {
  border-radius: 0;
  box-shadow: none;
}

#contact .form button[type="submit"] {
  background: #47974B ;
  border: 0;
  padding: 10px 24px;
  color: #fff;
  transition: 0.4s;
}

#contact .form button[type="submit"]:hover {
  background: #25d8fc;
}

// end contact 

  .section-description{
  color:black ;
  font-size:16px;
  }
  @media only screen and (max-width: 1000px) {
  #home{
  width:100%;
  }
  #responsive1{
  width:100%;
  margin-left:25%;
  }
  }


.form-icon{
color:#47974B;
}
.input-group-addon{
padding:7px;
}
.form-content-icon{
color:#47974B ;
font-size:30px;
position:relative;
top:10px;
}
.contact-head{
font-size:24px;
font-weight:Bold;
padding:10px 0px;
color:#47974B ;
}
.contact-ourcontact{

padding:7px 2px;font-size:18px;
}
.contact-subhead{
padding:0px 15px;

}

 
@media screen and (max-width: 480px) {
  #form-data {
    display:flex;
    flex-direction: column;
    color: lightgreen;
    

  
 
  }
} 
