.active_tab {
    border-bottom: 4px solid darkcyan !important;
    border-radius: 8px !important;
    background: azure;
    border: 1px solid darkcyan;
    font-weight: bold;
}
 .not_active_tab {
    border-radius: 8px !important;
    border: 1px solid darkcyan;
    font-weight: 600;
 }