.header-grid {
    display: grid;
    grid-template-columns: 3% 75% 13% 9%;
    // border : 1px solid gray;
    margin-left: 1rem ;
    font-weight: bold;
}

.table-grid {
    display: grid;
    grid-template-columns: 3% 78% 6% 10%;
    border : 1px solid gray;
    padding: 0.5rem;
    margin: 0.5rem 0rem;
}

.header-title{
    border: 1px solid;
    padding: 0.5rem;
}

.logo {
    width: 20px;
    height: 20px;
    border-radius: 0px;
    float: right;
}

.save-button {
    border-radius: 20px;
    float: right;
    width: 8rem;
    height: 2rem;
}

.button-footer {
    display: flex;
    justify-content: flex-end;
    width: 81rem;
}