.custom-input-container {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #000;
    padding: 5px 0;
    position: relative;
    width: 300px;
  }
  
  .custom-input {
    border: none;
    outline: none;
    width: 100%;
    font-size: 14px;
    background: transparent;
    font-family: inherit;
    font-weight: 400;
  }
  
  .icons-container {
    display: flex;
    align-items: center;
    margin-left: 8px;
  }
  
  .icon {
    margin: 0 5px;
    font-size: 16px;
    cursor: pointer;
  }
  
  .success-icon {
    color: green;
  }
  
  .error-icon {
    color: red;
  }
  
.tableColor.table tbody tr td:first-child{
    color: green;
    text-align: left;
    cursor: pointer;
}

.tableColor.table tbody tr td:not(first-child){
    /* color: rgb(41, 41, 203); */
    font-weight: 600;
    text-align: center;
}

.tableRanks.table tbody tr td:first-child{
    color: green;
    text-align: left;
    font-weight: 600;
    padding: 0.25rem 0.9375rem;
}

.tableRanks.table tbody tr td:not(first-child){
    padding: 0.25rem 0.9375rem;
    text-align: center;
}

/* UserRanks Table  */
.tableUserRanks.table tbody tr td{
    font-weight: 600;
    text-align: center;
}

.tableUserRanks.table tbody tr th{
  text-align: center;
}
.tableUserRanks.table tbody tr td:first-child{
    color: green;
    font-weight: 600;
    text-align: center;
} 

.selectBox{
    padding: 0 !important;
    height: unset !important;
    color: unset !important;
    outline-offset: unset !important;
    font-weight: 400 !important;
    width: auto !important;
    border-width: 1px !important;
    border-style: solid !important;
    border-color: light-dark(rgb(118, 118, 118), rgb(133, 133, 133)) !important;
    border-image: initial !important;
    display: inline-flex !important;
    /* border: unset !important; */
}